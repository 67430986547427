import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          God damn, finally, we're here.
        </p>
        <p>Will add some more stuff in near future</p>
        <p>Auto-deploy done</p>
      </header>
    </div>
  );
}

export default App;
